import React from "react";
import {NavLink, useHistory} from 'react-router-dom';

const Menu = () => {
    const history = useHistory();
    return (
        <>
            <div>
                <aside id="leftsidebar" className="sidebar">
                    <div className="menu">
                        <ul className="list">
                            <li className="sidebar-user-panel active">
                                <div className="user-panel">
                                    <div className=" image">
                                        <img src="assets/images/user_120.png" className="user-img-style"
                                             alt="User Image"/>
                                    </div>
                                </div>
                                <div className="profile-usertitle">
                                    <div className="sidebar-userpic-name"> {localStorage.getItem("UserName")}</div>
                                    <div className="profile-usertitle-job ">{localStorage.getItem("Role")}</div>
                                </div>
                            </li>
                            <li className="header">-- Main</li>
                            <li>
                                <NavLink to={'/'} exact={true}>
                                    <i data-feather="monitor"></i>
                                    <span>Home</span>
                                </NavLink>
                            </li>
                            {
                                localStorage.getItem("Role") == "Admin" ?
                                    <>
                                        <li>
                                            <NavLink to={'/suppliers'} exact={true}>
                                                <i data-feather="users"></i>
                                                <span>Supplier Config.</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'/branch'} exact={true}>
                                                <i data-feather="home"></i>
                                                <span>Branch Config.</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'/category'} exact={true}>
                                                <i data-feather="box"></i>
                                                <span>Category Config.</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'/products'} exact={true}>
                                                <i data-feather="shopping-cart"></i>
                                                <span>Products Config.</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'/purchase'} exact={true}>
                                                <i data-feather="shopping-bag"></i>
                                                <span>Purchase Config.</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'/sale'} exact={true}>
                                                <i data-feather="grid"></i>
                                                <span>Sale Config.</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'/stock'} exact={true}>
                                                <i data-feather="layers"></i>
                                                <span>Stock Detail</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'/orders'} exact={true}>
                                                <i data-feather="shopping-cart"></i>
                                                <span>Orders Detail</span>
                                            </NavLink>
                                        </li>
                                    </> :
                                    <>
                                        <li>
                                            <NavLink to={'/customer-sale'} exact={true}>
                                                <i data-feather="grid"></i>
                                                <span>Sale Config.</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'/stock'} exact={true}>
                                                <i data-feather="layers"></i>
                                                <span>Stock Detail</span>
                                            </NavLink>
                                        </li>
                                    </>
                            }
                        </ul>
                    </div>
                </aside>
            </div>
        </>
    )
}
export default Menu;
