import React, {useState, useEffect, useRef} from 'react';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Card} from 'primereact/card';
import {Toast} from "primereact/toast";
import $ from 'jquery';
import AppLink from "../components/Link";
import {useHistory} from 'react-router-dom';
import {Dropdown} from "primereact/dropdown";

export default function AddProduct() {
    const navigate = useHistory();
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    const [categoryData, setCategoryData] = useState([]);
    const [subCategory, setSubCategory] = useState('');
    const [subCategoryData, setSubCategoryData] = useState([]);
    const [subCategoryFilterData, setSubCategoryFilterData] = useState([]);
    const [branch, setBranch] = useState('');
    const [branchData, setBranchData] = useState([]);
    const [code, setCode] = useState('');
    const [price, setPrice] = useState('');
    const [detail, setDetail] = useState('');
    const [qty, setQty] = useState('');
    const [supplier, setSupplier] = useState('');
    const [supplierData, setSupplierData] = useState([]);
    const [wholeSale, setWholeSale] = useState('');
    const [updateId, setUpdateId] = useState('');
    const [part,setPart] = useState('');
    const [low, setLow] = useState('0')
    useEffect(() => {
        function getQueryParams(qs) {
            qs = qs.split('+').join(' ');

            var params = {},
                tokens,
                re = /[?&]?([^=]+)=([^&]*)/g;

            while (tokens = re.exec(qs)) {
                params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
            }

            return params;
        }

        let res = getQueryParams(window.location.search);
        LoadCategory();
        LoadSubCategory();
        LoadBranch();
        LoadSupplier();
        if (res.id) {
            setUpdateId(res.id);
            LoadProduct(res.id);
        }

    }, []);

    async function LoadProduct(id) {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData();
        formdata.append("page", "LoadProduct");
        formdata.append("id", id);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Products.php`, requestOptions)
            .then(response => response.json())
            .then(async result => {
                // console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    // console.log(Data);
                    setCategory(Data[0].Category);
                    setSubCategory(Data[0].SubCategory);
                    setName(Data[0].Name);
                    setPrice(Data[0].Price);
                    setQty(Data[0].Qty);
                    setDetail(Data[0].Detail);
                    setBranch(Data[0].Branch)
                    setCode(Data[0].Code);
                    setSupplier(Data[0].Supplier)
                    setWholeSale(Data[0].WholeSale)
                    setPart(Data[0].PartNo)
                    setLow(Data[0].Low)
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    async function LoadCategory() {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "LoadCategory");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Category.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setCategoryData(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    //SubCategory
    async function LoadSubCategory() {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "LoadSubCategory");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Category.php`, requestOptions)
            .then(response => response.json())
            .then(result => {

                if (result.status == "1") {
                    let Data = result.data;
                    setSubCategoryData(Data);
                    setSubCategoryFilterData(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    async function LoadBranch() {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "LoadBranch");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Category.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setBranchData(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    async function LoadSupplier() {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "LoadSuppliers");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Products.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setSupplierData(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }


    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    const submitHandle = async (e) => {
        e.preventDefault();
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formEl = document.forms.Form;
        var formdata = new FormData(formEl);

        if (updateId) {
            formdata.append("page", "UpdateProduct");
            formdata.append("id", updateId);
            formdata.append("code", code);
        } else {
            formdata.append("page", "SaveProduct");
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Products.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                setLoading(false);
                if (result.status == "1") {
                    // let Data = result.Data;
                    $('#Form').trigger('reset');
                    setName("");
                    setCode("");
                    setCategory("")
                    setSubCategory("")
                    setPrice('')
                    setDetail("")
                    setBranch("")
                    setQty("")
                    setSupplier("")
                    setWholeSale("")
                    setPart("")
                    setLow('0')
                    showSuccess(updateId ? "Record Updated Successfully" : "Record Saved Successfully");
                    setUpdateId("");

                } else if (result.status == "2") {
                    toast.current.show({
                        severity: 'info',
                        summary: 'Already Exists !',
                        detail: 'BarCode already available in the store',
                        life: 3000
                    });
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    return (
        <section className="content">
            <Card
                title={() => {
                    return (
                        <>
                            <button onClick={() => navigate.go(-1)}
                                    style={{border: "none", outline: "none", background: "transparent"}}><i
                                className={'fas fa-arrow-left'}></i></button>
                            &nbsp;&nbsp;&nbsp;
                            <span>Add Product</span>
                        </>
                    )
                }} className="card">
                <Toast ref={toast}/>
                <form id={'Form'} onSubmit={submitHandle}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Category <code>*</code></label>
                                <Dropdown name={'category'} required={true} value={category} onChange={(e) => {
                                    setCategory(e.target.value);
                                    let val = e.target.value;
                                    if (val) {
                                        const filter = subCategoryFilterData.filter((d) => {
                                            return d.Category === val
                                        })
                                        // console.log(filter);
                                        setSubCategoryData(filter);
                                    } else {
                                        setSubCategoryData(subCategoryFilterData);
                                    }
                                }}
                                          options={categoryData} optionLabel="Name" optionValue={'id'}
                                          placeholder="Select Category" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>SubCategory <code>*</code></label>
                                <Dropdown name={'subcategory'} value={subCategory} required={true}
                                          onChange={(e) => setSubCategory(e.target.value)}
                                          options={subCategoryData} optionLabel="Name" optionValue={'id'}
                                          placeholder="Select SubCategory" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label>BarCode <code>*</code></label>
                            <InputText value={code} onChange={(e) => setCode(e.target.value)} name='code'
                                       placeholder={'Enter/Scan BarCode/QR Code'} required={true}
                                       disabled={updateId ? true : false}/>
                        </div>
                        <div className="col-md-6">
                            <label>Name <code>*</code></label>
                            <InputText value={name} onChange={(e) => setName(e.target.value)} name='name'
                                       placeholder={'Enter Name'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Price <code>*</code></label>
                            <InputText type={'number'} value={price} onChange={(e) => setPrice(e.target.value)}
                                       name='price'
                                       placeholder={'Enter Price'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>WholeSale Price <code>*</code></label>
                            <InputText type={'number'} value={wholeSale} onChange={(e) => setWholeSale(e.target.value)}
                                       name='wholesale'
                                       placeholder={'Enter WholeSale Price'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Quantity Available <code>*</code></label>
                            <InputText type={'number'} value={qty} onChange={(e) => setQty(e.target.value)}
                                       name='qty'
                                       placeholder={'Enter Quantity'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Part No <code>optional</code></label>
                            <InputText value={part} onChange={(e) => setPart(e.target.value)} name='part'
                                       placeholder={'Enter Part No'}/>
                        </div>
                        <div className="col-md-6">
                            <label>Low Stock Point <code>*</code></label>
                            <InputText value={low} onChange={(e) => setLow(e.target.value)} name='low' type={"number"}
                                       placeholder={'Enter Low Stock Point'}/>
                        </div>
                        <div className="col-md-6">
                            <label>Description <code>Short Detail *</code></label>
                            <InputText value={detail} onChange={(e) => setDetail(e.target.value)} name='detail'
                                       placeholder={'Enter Short Detail'} required={true}/>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Branch <code>*</code></label>
                                <Dropdown name={'branch'} value={branch}
                                          onChange={(e) => setBranch(e.target.value)}
                                          options={branchData} optionLabel="BranchName" optionValue={'id'}
                                          placeholder="Select Branch" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Product Images <code>Multiple Images *</code></label>
                                <InputText type={'file'} name={'upload[]'} multiple={true}
                                           accept={'.png,.jpeg,.jpg,.webp'} className={'form-control'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Supplier <code>*</code></label>
                                <Dropdown name={'supplier'} value={supplier}
                                          onChange={(e) => setSupplier(e.target.value)}
                                          options={supplierData} optionLabel="Name" optionValue={'id'}
                                          placeholder="Select Supplier" className='form-control'/>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        {
                            updateId ?
                                <Button label={loading ? "Updating..." : "Update Record"} severity={'help'}
                                        disabled={loading}/> :
                                <Button label={loading ? "Saving..." : "Save Record"} disabled={loading}/>
                        }
                        {/*<Button  label={loading ? "Saving..." : "Save Record"} disabled={loading} />*/}
                    </div>
                </form>
            </Card>
        </section>

    );
}
