import React, {useState, useEffect, useRef} from 'react';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Card} from 'primereact/card';
import {Toast} from "primereact/toast";
import $ from 'jquery';
import AppLink from "../components/Link";
import {useHistory} from 'react-router-dom';
import {Dropdown} from "primereact/dropdown";

export default function AddSale() {
    const navigate = useHistory();
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    const [categoryData, setCategoryData] = useState([]);
    const [subCategory, setSubCategory] = useState('');
    const [subCategoryData, setSubCategoryData] = useState([]);
    const [subCategoryFilterData, setSubCategoryFilterData] = useState([]);
    const [branch, setBranch] = useState('');
    const [branchData, setBranchData] = useState([]);
    const [supplier, setSupplier] = useState('');
    const [supplierData, setSupplierData] = useState([]);
    const [price, setPrice] = useState('');
    const [detail, setDetail] = useState('');
    const [qty, setQty] = useState('');
    const [bill, setBill] = useState('');
    const [newQty, setNewQty] = useState('');
    const [code, setCode] = useState('');
    const [updateId, setUpdateId] = useState('');
    const [purchaseBranch, setPurchaseBranch] = useState('');

    useEffect(() => {
        function getQueryParams(qs) {
            qs = qs.split('+').join(' ');

            var params = {},
                tokens,
                re = /[?&]?([^=]+)=([^&]*)/g;

            while (tokens = re.exec(qs)) {
                params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
            }

            return params;
        }

        let res = getQueryParams(window.location.search);
        LoadCategory();
        LoadSubCategory();
        LoadBranch();
        LoadSupplier();
        if (res.id) {
            setUpdateId(res.id);
            LoadProduct(res.id);
        }

    }, []);

    async function LoadSupplier() {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "LoadSupplier");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Products.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setSupplierData(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    async function LoadProduct(id) {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData();
        formdata.append("page", "LoadSaleDetail");
        formdata.append("id", id);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Products.php`, requestOptions)
            .then(response => response.json())
            .then(async result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    // console.log(Data);
                    setCategory(Data[0].Category);
                    setSubCategory(Data[0].SubCategory);
                    setName(Data[0].Name);
                    setPrice(Data[0].Price);
                    setQty(Data[0].Qty);
                    setDetail(Data[0].Detail);
                    setBranch(Data[0].Branch)
                    setSupplier(Data[0].Supplier)
                    setBill(Data[0].Amount)
                    setNewQty(Data[0].NewQty)
                    setCode(Data[0].Code)
                    setPurchaseBranch(Data[0].Branch)
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    async function LoadCategory() {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "LoadCategory");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Category.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setCategoryData(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    //SubCategory
    async function LoadSubCategory() {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "LoadSubCategory");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Category.php`, requestOptions)
            .then(response => response.json())
            .then(result => {

                if (result.status == "1") {
                    let Data = result.data;
                    setSubCategoryData(Data);
                    setSubCategoryFilterData(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    async function LoadBranch() {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "LoadBranch");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Category.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setBranchData(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }


    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    const submitHandle = async (e) => {
        e.preventDefault();
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formEl = document.forms.Form;
        var formdata = new FormData(formEl);
        formdata.append("code",code);
        if (updateId) {
            formdata.append("page", "UpdateSale");
            formdata.append("id", updateId);
        } else {
            formdata.append("page", "SaveSale");
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Products.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                setLoading(false);
                if (result.status == "1") {
                    // let Data = result.Data;
                    $('#Form').trigger('reset');
                    setName("");
                    setCategory("")
                    setSubCategory("")
                    setPrice('')
                    setDetail("")
                    setBranch("")
                    setQty("")
                    setSupplier("")
                    setBill("")
                    setNewQty("")
                    setCode("");
                    showSuccess(updateId ? "Record Updated Successfully" : "Record Saved Successfully");
                    setUpdateId("");

                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    const LoadProductDetail=async ()=>{
        if (code)
        {
            setLoading(true);
            var myHeaders = new Headers();
            myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
            var formdata = new FormData();
            formdata.append("page", "LoadProductDetailForSale");
            formdata.append("id", code);
            formdata.append("branch", localStorage.getItem("Branch"));
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            await fetch(`${AppLink}Products.php`, requestOptions)
                .then(response => response.json())
                .then(async result => {
                    console.log(result);
                    if (result.status == "1") {
                        let Data = result.data;
                        // console.log(Data);
                        if (Data.length) {
                            setCategory(Data[0].Category);
                            setSubCategory(Data[0].SubCategory);
                            setName(Data[0].Name);
                            setPrice(Data[0].Price);
                            setQty(Data[0].Qty);
                            setDetail(Data[0].Detail);
                            setBranch(Data[0].Branch)
                            setCode(Data[0].Code);
                            setLoading(false);
                        }
                        else
                        {
                            setName("");
                            // setCode("");
                            setCategory("")
                            setSubCategory("")
                            setPrice('')
                            setDetail("")
                            setBranch("")
                            setQty("")
                            setLoading(false);
                            alert("No Product Found !")
                        }
                    } else {
                        showError();
                        console.log("Server Error. Please Try Again Later.(Code : 403)");
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    return (
        <section className="content">
            <Card
                title={() => {
                    return (
                        <>
                            <button onClick={() => navigate.go(-1)}
                                    style={{border: "none", outline: "none", background: "transparent"}}><i
                                className={'fas fa-arrow-left'}></i></button>
                            &nbsp;&nbsp;&nbsp;
                            <span>Sale Items</span>
                        </>
                    )
                }} className="card">
                <Toast ref={toast}/>
                <div className="row">
                    <div className="col-md-6">
                        <label>Scan/Enter BarCode <code>*</code></label>
                        <InputText value={code} onChange={(e) => setCode(e.target.value)} name='code'
                                   disabled={updateId ? true : false} placeholder={'BarCode........'} autoFocus={true} onKeyPress={(e)=> {
                            if (e.key == "Enter")
                            {
                                LoadProductDetail();
                            }
                        }} />
                    </div>
                </div>
                <form id={'Form'} onSubmit={submitHandle}>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Category <code>*</code></label>
                                <Dropdown name={'category'} disabled={true} value={category} onChange={(e) => {
                                    setCategory(e.target.value);
                                    let val = e.target.value;
                                    if (val) {
                                        const filter = subCategoryFilterData.filter((d) => {
                                            return d.Category === val
                                        })
                                        // console.log(filter);
                                        setSubCategoryData(filter);
                                    } else {
                                        setSubCategoryData(subCategoryFilterData);
                                    }
                                }}
                                          options={categoryData} optionLabel="Name" optionValue={'id'}
                                          placeholder="Select Category" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>SubCategory <code>*</code></label>
                                <Dropdown name={'subcategory'} disabled={true} value={subCategory} required={true}
                                          onChange={(e) => setSubCategory(e.target.value)}
                                          options={subCategoryData} optionLabel="Name" optionValue={'id'}
                                          placeholder="Select SubCategory" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label>Name <code>*</code></label>
                            <InputText value={name} onChange={(e) => setName(e.target.value)} name='name'
                                       placeholder={'Enter Name'} disabled={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Price <code>*</code></label>
                            <InputText type={'number'} value={price} onChange={(e) => setPrice(e.target.value)}
                                       name='price'
                                       placeholder={'Enter Price'} disabled={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Quantity Available <code>*</code></label>
                            <InputText type={'number'} value={qty} onChange={(e) => setQty(e.target.value)}
                                       name='qty'
                                       placeholder={'Enter Quantity'} disabled={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Detail <code>Short Detail *</code></label>
                            <InputText value={detail} onChange={(e) => setDetail(e.target.value)} name='detail'
                                       placeholder={'Enter Short Detail'} disabled={true}/>
                        </div>

                        <div className="col-md-6 hide">
                            <div className="form-group">
                                <label>Branch <code>*</code></label>
                                <Dropdown name={'branch'} value={branch} disabled
                                          onChange={(e) => setBranch(e.target.value)}
                                          options={branchData} optionLabel="BranchName" optionValue={'id'}
                                          placeholder="Select Branch" className='form-control'/>
                            </div>
                        </div>
                    </div>
                    <div className="divider" />
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <h5>Sale Details</h5>
                        </div>
                        <div className={updateId ? "col-md-6 hide" : "col-md-6"}>
                            <div className="form-group">
                                <label>Branch <code>*</code></label>
                                <Dropdown name={'purchaseBranch'} value={purchaseBranch}
                                          onChange={(e) => setPurchaseBranch(e.target.value)}
                                          options={branchData} optionLabel="BranchName" optionValue={'id'}
                                          placeholder="Select Branch" className='form-control'/>
                            </div>
                        </div>
                        <div className={updateId ? "col-md-6"  : "col-md-6 hide"}>
                            <div className="form-group">
                                <label>Branch <code>*</code></label>
                                <Dropdown value={purchaseBranch}
                                          disabled={true}
                                          options={branchData} optionLabel="BranchName" optionValue={'id'}
                                          placeholder="Select Branch" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label>Sale Qty <code>*</code></label>
                            <InputText value={newQty} onChange={(e) => setNewQty(e.target.value)} name='newQty'
                                       placeholder={'Enter Sale Qty'} type={'number'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Total Bill Amount <code>*</code></label>
                                <InputText placeholder={'Enter Bill Amount'} type={'number'} name={'bill'} value={bill} onChange={(e)=> setBill(e.target.value)} className={'form-control'}/>
                            </div>
                        </div>

                    </div>
                    <div className="row mt-3">
                        {
                            updateId ?
                                <Button label={loading ? "Updating..." : "Update Record"} severity={'help'}
                                        disabled={loading}/> :
                                <Button label={loading ? "Saving..." : "Save Record"} disabled={loading}/>
                        }
                        {/*<Button  label={loading ? "Saving..." : "Save Record"} disabled={loading} />*/}
                    </div>
                </form>
            </Card>
        </section>

    );
}
