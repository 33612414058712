import React, {useState, useEffect, useRef} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Card} from 'primereact/card';
import {useHistory} from "react-router-dom";
import AppLink, {ImageLink} from "../components/Link";
import {Toast} from "primereact/toast";
import {ConfirmPopup, confirmPopup} from 'primereact/confirmpopup';

export default function Products() {
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [customerData, setCustomerData] = useState([]);
    const [filterRecord, setFilterRecord] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [updateId,setUpdateId] = useState('');
    const dt = useRef(null);
    useEffect(() => {
        LoadData();
    }, [])
    const cols = [
        { field: 'PartNo', header: 'PartNo' },
        { field: 'Code', header: 'Code' },
        { field: 'Category', header: 'Category' },
        { field: 'SubCategory', header: 'SubCategory' },
        { field: 'Name', header: 'Name' },
        { field: 'Detail', header: 'Detail' },
        { field: 'Price', header: 'Price' },
        { field: 'Supplier', header: 'Supplier' },
    ];
    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));
    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    const LoadData = async () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "LoadProducts");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Products.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setCustomerData(Data);
                    setFilterRecord(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    const filterData = (e) => {
        let val = e.target.value.toLowerCase();
        if (val) {
            const filter = filterRecord.filter((d) => {
                return d.Code.toLowerCase().indexOf(val) > -1 || d.Name.toLowerCase().indexOf(val) > -1 || d.Branch.toLowerCase().indexOf(val) > -1 || d.Price.toLowerCase().indexOf(val) > -1 || d.Category.toLowerCase().indexOf(val) > -1 || d.SubCategory.toLowerCase().indexOf(val) > -1
            })
            console.log(filter);
            setCustomerData(filter);
        } else {
            setCustomerData(filterRecord);
        }
    }

    function filterDateRecord() {
        const filter = filterRecord.filter((d) => {
            return d.Date >= startDate && d.Date <= endDate
        })
        console.log(filter);
        setCustomerData(filter);
    }

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, customerData);
                doc.save('products.pdf');
            });
        });
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(customerData);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'products');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });
                module.default.saveAs(data, fileName + EXCEL_EXTENSION);
            }
        });
    };

    return (
        <section className="content">
            <Card header={() => {
                return (
                    <>
                        <div className="row align-items-center" style={{ margin : 15 }}>
                            <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
                            <Button className={'ml-2'} type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
                            <Button className={'ml-2'} type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf} data-pr-tooltip="PDF" />
                        </div>
                        <div className="row justify-content-end gap-4 mt-4">
                            <Button onClick={() => history.push('/add-product')} label="Add Product"
                                    icon="pi pi-plus"
                                    style={{width: "auto"}}/>
                        </div>
                    </>


                )
            }
            } title={() => {
                return (
                    <>
                        <button onClick={() => history.go(-1)}
                                style={{border: "none", outline: "none", background: "transparent"}}><i
                            className={'fas fa-arrow-left'}></i></button>
                        &nbsp;&nbsp;&nbsp;
                        <span>Products List</span>
                        <div className="row justify-content-between">
                            <div className="col-md-3 mt-1">
                                <div className="form-group">
                                    <InputText type={'search'} onChange={(e) => filterData(e)}
                                               placeholder={'Keyword Search...'}/>
                                </div>
                            </div>
                            <div className="col-md-7 mt-1">
                                <div className="row">
                                    <div className="col-md-4 mt-1">
                                        <div className="form-group">
                                            <InputText type={'date'} onChange={(e) => setStartDate(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-1">
                                        <div className="form-group">
                                            <InputText type={'date'} onChange={(e) => setEndDate(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mt-1">
                                        <div className="form-group">
                                            <button onClick={filterDateRecord}
                                                    className={'btn btn-primary form-control'}>Search
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }} className="card">
                <Toast ref={toast}/>
                <ConfirmPopup/>
                <DataTable ref={dt} value={customerData} loading={loading} emptyMessage={'No Record Found !'} paginator
                           rows={10}
                           onRowClick={async (p) => {
                               let id = customerData[p.index].id;
                               setUpdateId(id);
                           }}
                           rowsPerPageOptions={[5, 10, 25, 50]}
                           tableStyle={{minWidth: '50rem', overflow: "auto"}}
                >
                    <Column field="Category" header="Category" style={{minWidth: '10rem'}}/>
                    <Column field="SubCategory" header="SubCategory" style={{minWidth: '10rem'}}/>
                    <Column field="Code" header="BarCode" style={{minWidth: '10rem'}}/>
                    <Column field="Name" header="Name" style={{minWidth: '10rem'}}/>
                    <Column field="PartNo" header="Part No" style={{minWidth: '10rem'}}/>
                    <Column field="Price" header="Price" style={{minWidth: '10rem'}}/>
                    <Column field="WholeSale" header="WholeSale Price" style={{minWidth: '10rem'}}/>
                    <Column field="Low" header="Low Stock Point" style={{minWidth: '10rem'}}/>
                    <Column field="Detail" header="Detail" style={{minWidth: '10rem'}}/>
                    <Column field="Supplier" header="Supplier" style={{minWidth: '10rem'}}/>
                    {/*<Column field="Branch" header="Branch" style={{minWidth: '10rem'}}/>*/}
                    <Column header="Image" style={{minWidth: '10rem'}} body={(p) => {
                        var link = p.Images;
                        link = link.split('___');
                        return (
                            <a href={ImageLink + link[0]} target={'_blank'}>
                                <img src={ImageLink + link[0]} style={{width: 80, height: 80, borderRadius: 4}}/>
                            </a>
                        )
                    }}/>
                    <Column header={'Edit'} style={{minWidth : '5rem'}} body={(p)=>{
                        return (
                            <Button icon={'pi pi-pencil'} severity={'info'} onClick={() => {
                                history.push("/add-product?id=" + p.id);
                            }}/>
                        )
                    }} />
                    <Column header={'Delete'} style={{minWidth : '5rem'}} body={(p)=>{
                        const acceptDelete = async () => {
                            var myHeaders = new Headers();
                            myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
                            var formdata = new FormData();
                            formdata.append("page", "DeleteProduct");
                            formdata.append("id", p.id);
                            var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: formdata,
                                redirect: 'follow'
                            };

                            await fetch(`${AppLink}Products.php`, requestOptions)
                                .then(response => response.json())
                                .then(result => {
                                    console.log(result);
                                    setLoading(false);
                                    if (result.status == "1") {
                                        // let Data = result.Data;
                                        LoadData();
                                        showSuccess("Record Deleted Successfully");

                                    } else {
                                        showError();
                                        console.log("Server Error. Please Try Again Later.(Code : 403)");
                                    }
                                })
                                .catch(error => console.log('error', error));
                        }
                        return (
                            <Button icon={'pi pi-trash'} severity={'danger'} onClick={async (event) => {
                                confirmPopup({
                                    target: event.currentTarget,
                                    message: 'Do you want to delete this record?',
                                    icon: 'pi pi-info-circle',
                                    acceptClassName: 'p-button-danger',
                                    accept() {
                                        acceptDelete()
                                    },
                                    reject() {
                                        console.log("Reject");
                                    }
                                });


                            }}/>
                        )
                    }} />
                </DataTable>
            </Card>
        </section>
    );
}
