import AppLink from "../components/Link";
import React, {useEffect, useRef, useState} from "react";
import {Toast} from "primereact/toast";

const Home = () => {
    const toast = useRef(null);
    const [saleDetail, setSaleDetail] = useState([]);
    const [branchData, setBranchData] = useState([]);
    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    useEffect(() => {
        LoadHomeDetails();
    }, [])

    const LoadHomeDetails = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        if (localStorage.getItem("Role") == "Admin") {
            formdata.append("page", "MainStoreSalePurchase");
        }
        else
        {
            formdata.append("page", "SingleBranchSalePurchase");
            formdata.append("UserId",localStorage.getItem("UserId"));
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Products.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setSaleDetail(Data[0]);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "BranchSalePurchase");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Products.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setBranchData(Data);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));

    }

    return (

        <section className="content">
            <Toast ref={toast}/>
            <div className="container-fluid">
                <div className="block-header">
                    <div className="row mb-0">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <ul className="breadcrumb breadcrumb-style ">
                                <li className="breadcrumb-item">
                                    <h4 className="page-title">Dashboard</h4>
                                </li>
                                <li className="breadcrumb-item bcrumb-1">
                                    <a href="./">
                                        <i className="fas fa-home"></i> Home</a>
                                </li>
                                <li className="breadcrumb-item active">Dashboard</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        {
                            localStorage.getItem("Role") == "Admin" ?
                                <>
                                    <h4>Main Branch</h4>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="info-box7 l-bg-green order-info-box7">
                                            <div className="info-box7-block">
                                                <h4 className="m-b-20">Total Sale</h4>
                                                <h2 style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "space-between"
                                                }}><i
                                                    className="fas fa-balance-scale text-white"></i><span
                                                    className={'text-white'}
                                                    style={{fontWeight: "bolder"}}>$ {saleDetail.TotalSale ? saleDetail.TotalSale : 0}</span></h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="info-box7 l-bg-purple order-info-box7">
                                            <div className="info-box7-block">
                                                <h4 className="m-b-20">Total Purchase</h4>
                                                <h2 style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "space-between"
                                                }}><i
                                                    className="fas fa-balance-scale text-white"></i><span
                                                    className={'text-white'}
                                                    style={{fontWeight: "bolder"}}>$ {saleDetail.TotalPurchase ? saleDetail.TotalPurchase : 0}</span>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        branchData.map((b) => {
                                            return (
                                                <div className={'row mt-2'}>
                                                    <div className="row">
                                                        <h4>{b.BranchName}</h4>
                                                        <small>(Sub Branch)</small>
                                                    </div>

                                                    <div className="col-lg-3 col-sm-6">
                                                        <div className="info-box7 l-bg-green order-info-box7">
                                                            <div className="info-box7-block">
                                                                <h4 className="m-b-20">Total Sale</h4>
                                                                <h2 style={{
                                                                    width: "100%",
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    alignItems: "center",
                                                                    justifyContent: "space-between"
                                                                }}><i
                                                                    className="fas fa-balance-scale text-white"></i><span
                                                                    className={'text-white'}
                                                                    style={{fontWeight: "bolder"}}>$ {b.TotalSale ? b.TotalSale : 0}</span>
                                                                </h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-6">
                                                        <div className="info-box7 l-bg-purple order-info-box7">
                                                            <div className="info-box7-block">
                                                                <h4 className="m-b-20">Total Purchase</h4>
                                                                <h2 style={{
                                                                    width: "100%",
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    alignItems: "center",
                                                                    justifyContent: "space-between"
                                                                }}><i
                                                                    className="fas fa-balance-scale text-white"></i><span
                                                                    className={'text-white'}
                                                                    style={{fontWeight: "bolder"}}>$ {b.TotalPurchase ? b.TotalPurchase : 0}</span>
                                                                </h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </> :
                                <>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="info-box7 l-bg-green order-info-box7">
                                            <div className="info-box7-block">
                                                <h4 className="m-b-20">Total Sale</h4>
                                                <h2 style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "space-between"
                                                }}><i
                                                    className="fas fa-balance-scale text-white"></i><span
                                                    className={'text-white'}
                                                    style={{fontWeight: "bolder"}}>$ {saleDetail.TotalSale}</span></h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="info-box7 l-bg-purple order-info-box7">
                                            <div className="info-box7-block">
                                                <h4 className="m-b-20">Total Purchase</h4>
                                                <h2 style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "space-between"
                                                }}><i
                                                    className="fas fa-balance-scale text-white"></i><span
                                                    className={'text-white'}
                                                    style={{fontWeight: "bolder"}}>$ {saleDetail.TotalPurchase}</span>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }


                    </div>
                </div>
            </div>
        </section>

    )
}
export default Home;
