import React, {useEffect, useRef, useState} from "react";
import {TabView, TabPanel} from 'primereact/tabview';
import {Toast} from "primereact/toast";
import {useHistory} from "react-router-dom";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import AppLink from "../components/Link";
import {ConfirmPopup, confirmPopup} from 'primereact/confirmpopup';


export default function SellerConfig() {
    const navigate = useHistory();
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [username, setUserName] = useState('');
    const [company, setCompany] = useState('');
    const [contact, setContact] = useState('');
    const [email, setEmail] = useState('');
    const [website, setWebsite] = useState('');
    const [fax, setFax] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [address, setAddress] = useState('');
    const [branchData, setBranchData] = useState([]);
    const [updateId, setUpdateId] = useState('');
    //Toaster Messages
    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    useEffect(() => {
        LoadBranch();
    }, [])

    async function LoadBranch() {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "LoadSupplier");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Products.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setBranchData(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    const submitHandle = async (e) => {
        e.preventDefault();
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formEl = document.forms.Form;
        var formdata = new FormData(formEl);
        if (updateId) {
            formdata.append("page", "UpdateSupplier");
            formdata.append("id", updateId);
        } else {
            formdata.append("page", "SaveSupplier");
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Products.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    LoadBranch();
                    showSuccess(updateId ? "Record Updated Successfully" : "Record Saved Successfully");
                    setCompany("");
                    setUserName("")
                    setContact("")
                    setEmail("")
                    setWebsite("")
                    setFax("")
                    setAddress("")
                    setCity("")
                    setState("")
                    setZip("")
                    setUpdateId("")
                }
                else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
                setLoading(false);
            })
            .catch(error => console.log('error', error));
    }

    const dt = useRef(null);

    const cols = [
        { field: 'Company', header: 'Company' },
        { field: 'Name', header: 'Name' },
        { field: 'ContactNo', header: 'ContactNo' },
        { field: 'Email', header: 'Email' },
        { field: 'Fax', header: 'Fax' },
        { field: 'Website', header: 'Website' },
        { field: 'State', header: 'State' },
        { field: 'City', header: 'City' },
        { field: 'Zip', header: 'Zip' },
        { field: 'Address', header: 'Address' },
    ];
    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));
    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, branchData);
                doc.save('supplier.pdf');
            });
        });
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(branchData);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'supplier');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });
                module.default.saveAs(data, fileName + EXCEL_EXTENSION);
            }
        });
    };

    return (
        <section className="content">
            <Toast ref={toast}/>
            <ConfirmPopup/>
            <div className="card">
                <TabView>
                    <TabPanel header="Supplier/Seller Config.">
                        <form id={'Form'} onSubmit={submitHandle}>
                            <div className="row">
                                <div className="col-md-6">
                                    <label>Company Name <code>*</code></label>
                                    <InputText value={company} onChange={(e) => setCompany(e.target.value)}
                                               name={'company'}
                                               required={true} placeholder={'Enter Company Name'}/>
                                </div>
                                <div className="col-md-6">
                                    <label>Supplier Name <code>*</code></label>
                                    <InputText value={username} onChange={(e) => setUserName(e.target.value)}
                                               name={'name'}
                                               required={true} placeholder={'Enter Name'}/>
                                </div>
                                <div className="col-md-6">
                                    <label>Contact No <code>*</code></label>
                                    <InputText type={'number'} value={contact} onChange={(e) => setContact(e.target.value)}
                                               name={'contact'}
                                               required={true} placeholder={'Enter ContactNo'}/>
                                </div>
                                <div className="col-md-6">
                                    <label>Email Address <code>*</code></label>
                                    <InputText type={'email'} value={email} onChange={(e) => setEmail(e.target.value)}
                                               name={'email'}
                                               required={true} placeholder={'Enter Email Address'}/>
                                </div>
                                <div className="col-md-6">
                                    <label>Fax </label>
                                    <InputText type={'number'} value={fax} onChange={(e) => setFax(e.target.value)}
                                               name={'fax'}
                                               placeholder={'Enter Fax'}/>
                                </div>
                                <div className="col-md-6">
                                    <label>Website </label>
                                    <InputText value={website} onChange={(e) => setWebsite(e.target.value)}
                                               name={'website'}
                                               placeholder={'Enter Website'}/>
                                </div>
                                <div className="col-md-6">
                                    <label>City</label>
                                    <InputText value={city} onChange={(e) => setCity(e.target.value)}
                                               name={'city'}
                                               placeholder={'Enter City'}/>
                                </div>
                                <div className="col-md-6">
                                    <label>State</label>
                                    <InputText value={state} onChange={(e) => setState(e.target.value)}
                                               name={'state'}
                                               placeholder={'Enter State'}/>
                                </div>
                                <div className="col-md-6">
                                    <label>Zip Code</label>
                                    <InputText type={'number'} value={zip} onChange={(e) => setZip(e.target.value)}
                                               name={'zip'}
                                               placeholder={'Enter Zip'}/>
                                </div>
                                <div className="col-md-6">
                                    <label>Address</label>
                                    <InputText value={address} onChange={(e) => setAddress(e.target.value)}
                                               name={'address'}
                                               placeholder={'Enter Address'}/>
                                </div>

                                <div className="col-md-12">
                                    <label>&nbsp;</label>
                                    {
                                        updateId ?
                                            <Button label={loading ? "Updating..." : "Update Record"} disabled={loading}
                                                    className={'form-control'} severity={'help'}
                                            />
                                            :
                                            <Button label={loading ? "Saving..." : "Save Record"} disabled={loading}
                                                    className={'form-control'}/>
                                    }
                                </div>
                            </div>
                        </form>
                        <div className="row align-items-center" style={{ margin : 15 }}>
                            <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
                            <Button className={'ml-2'} type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
                            <Button className={'ml-2'} type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf} data-pr-tooltip="PDF" />
                        </div>
                        <div className="row mt-4">
                            <DataTable ref={dt} value={branchData} loading={loading} emptyMessage={'No Record Found !'}
                                       paginator
                                       rows={10}
                                       rowsPerPageOptions={[5, 10, 25, 50]}
                                       tableStyle={{minWidth: '50rem', overflow: "auto"}}
                            >
                                <Column field="Company" header="Company Name" style={{minWidth: '10rem'}}/>
                                <Column field="Name" header="Supplier Name" style={{minWidth: '10rem'}}/>
                                <Column field="ContactNo" header="Contact No." style={{minWidth: '10rem'}}/>
                                <Column field="Email" header="Email Address" style={{minWidth: '10rem'}}/>
                                <Column field="Fax" header="Fax No." style={{minWidth: '10rem'}}/>
                                <Column field="Website" header="Website" style={{minWidth: '10rem'}}/>
                                <Column field="City" header="City" style={{minWidth: '10rem'}}/>
                                <Column field="State" header="State" style={{minWidth: '10rem'}}/>
                                <Column field="Zip" header="Zip Code" style={{minWidth: '10rem'}}/>
                                <Column field="Address" header="Address" style={{minWidth: '10rem'}}/>
                                <Column header="Delete" style={{maxWidth: '5rem'}} body={(p) => {
                                    const acceptDelete = async () => {
                                        var myHeaders = new Headers();
                                        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
                                        var formdata = new FormData();
                                        formdata.append("page", "DeleteSupplier");
                                        formdata.append("id", p.id);
                                        var requestOptions = {
                                            method: 'POST',
                                            headers: myHeaders,
                                            body: formdata,
                                            redirect: 'follow'
                                        };

                                        await fetch(`${AppLink}Products.php`, requestOptions)
                                            .then(response => response.json())
                                            .then(result => {
                                                console.log(result);
                                                setLoading(false);
                                                if (result.status == "1") {
                                                    // let Data = result.Data;
                                                    LoadBranch();
                                                    showSuccess("Record Deleted Successfully");
                                                } else {
                                                    showError();
                                                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                                                }
                                            })
                                            .catch(error => console.log('error', error));
                                    }
                                    return (
                                        <Button icon={'pi pi-trash'} severity={'danger'} onClick={async (event) => {
                                            confirmPopup({
                                                target: event.currentTarget,
                                                message: 'Do you want to delete this record?',
                                                icon: 'pi pi-info-circle',
                                                acceptClassName: 'p-button-danger',
                                                accept() {
                                                    acceptDelete()
                                                },
                                                reject() {
                                                    console.log("Reject");
                                                }
                                            });


                                        }}/>
                                    )
                                }}/>
                                <Column header={'Edit'} style={{maxWidth: '5rem'}} body={(p) => {
                                    return (
                                        <Button icon={'pi pi-pencil'} severity={'info'} onClick={() => {
                                            setUpdateId(p.id);
                                            setCompany(p.Company)
                                            setUserName(p.Name)
                                            setContact(p.ContactNo)
                                            setEmail(p.Email)
                                            setFax(p.Fax)
                                            setWebsite(p.Website)
                                            setCity(p.City)
                                            setState(p.State)
                                            setZip(p.Zip)
                                            setAddress(p.Address)
                                        }}/>
                                    )
                                }}/>
                            </DataTable>
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        </section>
    )
}
