import './App.css';
import React from "react";
import Header from "./components/Header";
import Menu from "./components/Menu";
import {Route, Switch} from "react-router-dom";
import Home from "./screens/Home";
import NotFound from "./screens/NotFound";
import Login from "./screens/Login";
import CategoryConfig from "./screens/CategoryConfig";
import BranchConfig from "./screens/BranchConfig";
import Products from "./screens/Products";
import AddProduct from "./screens/AddProduct";
import SellerConfig from "./screens/SellerConfig";
import Stock from "./screens/Stock";
import Purchase from "./screens/Purchase";
import AddPurchase from "./screens/AddPurchase";
import AddSale from "./screens/AddSale";
import Sale from "./screens/Sale";
import CustomerSale from "./screens/CustomerSale";
import AddCustomerSale from "./screens/AddCustomerSale";
import Orders from "./screens/Orders";
import Profile from "./screens/Profile";

function App() {
    return (
        <>
            {/*<div className="loader"></div>*/}
            <Switch>
                <Route exact path='/login' component={Login}/>
                <div id="app">
                    <div className="main-wrapper main-wrapper-1">
                        <Header/>
                        <Menu/>
                        <Route exact path='/' component={Home}/>
                        <Route exact path='/category' component={CategoryConfig}/>
                        <Route exact path='/branch' component={BranchConfig}/>
                        <Route exact path='/suppliers' component={SellerConfig}/>
                        <Route exact path='/products' component={Products}/>
                        <Route exact path='/add-product' component={AddProduct}/>
                        <Route exact path='/purchase' component={Purchase}/>
                        <Route exact path='/purchase-product' component={AddPurchase}/>
                        <Route exact path='/sale' component={Sale}/>
                        <Route exact path='/customer-sale' component={CustomerSale}/>
                        <Route exact path='/sale-product' component={AddSale}/>
                        <Route exact path='/add-sale' component={AddCustomerSale}/>
                        <Route exact path='/stock' component={Stock}/>
                        <Route exact path='/orders' component={Orders}/>
                        <Route exact path='/profile' component={Profile}/>
                    </div>
                </div>
                <Route component={NotFound}/>
            </Switch>


            {/*<div id="app">*/}
            {/*    <div className="main-wrapper main-wrapper-1">*/}

            {/*        <Header />*/}
            {/*        <Menu />*/}

            {/*        <Footer />*/}

            {/*    </div>*/}
            {/*</div>*/}

        </>
    );
}

export default App;
