import React, {useState, useEffect, useRef} from 'react';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Card} from 'primereact/card';
import {Toast} from "primereact/toast";
import $ from 'jquery';
import AppLink from "../components/Link";
import {useHistory} from 'react-router-dom';
import {Dropdown} from "primereact/dropdown";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";

export default function AddCustomerSale() {
    const navigate = useHistory();
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [bill, setBill] = useState(0);
    const [code, setCode] = useState('');
    const [updateId, setUpdateId] = useState('');
    const [saleData, setSaleData] = useState([]);
    const [customer, setCustomer] = useState('');
    const [no,setNo] = useState(0);
    useEffect(() => {
        function getQueryParams(qs) {
            qs = qs.split('+').join(' ');

            var params = {},
                tokens,
                re = /[?&]?([^=]+)=([^&]*)/g;

            while (tokens = re.exec(qs)) {
                params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
            }

            return params;
        }

        let res = getQueryParams(window.location.search);
        if (res.id) {
            setUpdateId(res.id);
            LoadProduct(res.id);
        }

    }, []);
    async function LoadProduct(id) {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData();
        formdata.append("page", "LoadSaleDetail");
        formdata.append("id", id);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Products.php`, requestOptions)
            .then(response => response.json())
            .then(async result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    const submitHandle = async (e) => {
        e.preventDefault();
        if (saleData.length > 0) {
            setLoading(true);
            var myHeaders = new Headers();
            myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
            var formEl = document.forms.Form;
            var formdata = new FormData(formEl);
            if (updateId) {
                formdata.append("page", "UpdateSaleMaster");
                formdata.append("id", updateId);
            } else {
                formdata.append("page", "SaveSaleMaster");
            }
            formdata.append("UserId", localStorage.getItem("UserId"));
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            await fetch(`${AppLink}Products.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result);
                    setLoading(false);
                    if (result.status == "1") {
                        // let Data = result.Data;
                        for (let i = 0; i < saleData.length; i++) {
                            SaveSaleDetail(i, result.id);
                        }
                        setSaleData([]);
                        setCustomer("");
                        setBill("");
                        setNo(0);
                        showSuccess(updateId ? "Record Updated Successfully" : "Record Saved Successfully");
                        setUpdateId("");
                    } else {
                        showError();
                        console.log("Server Error. Please Try Again Later.(Code : 403)");
                    }
                })
                .catch(error => console.log('error', error));
        }
        else
        {
            toast.current.show({severity: 'info', summary: 'Empty', detail: 'Please add at least 1 item to sale', life: 3000});
        }
    }

    const SaveSaleDetail=async (ind,id)=>{
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData();
        console.log(saleData[ind].Qty);
        formdata.append("code",saleData[ind].Code);
        formdata.append("qty",saleData[ind].Qty);
        formdata.append("price",saleData[ind].Price);
        formdata.append("id",id);
        formdata.append("UserId", localStorage.getItem("UserId"));
        if (updateId)
        {
            formdata.append("page", "UpdateSaleDetail");
            formdata.append("id", updateId);
        } else {
            formdata.append("page", "SaveSaleDetail");
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Products.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                setLoading(false);
                if (result.status == "1") {
                    // let Data = result.Data;
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    const LoadProductDetail = async () => {
        if (code) {
            setLoading(true);
            var myHeaders = new Headers();
            myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
            var formdata = new FormData();
            formdata.append("page", "LoadProductDetailForSale");
            formdata.append("id", code);
            formdata.append("branch", localStorage.getItem("Branch"));
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            await fetch(`${AppLink}Products.php`, requestOptions)
                .then(response => response.json())
                .then(async result => {
                    console.log(result);
                    if (result.status == "1") {
                        let Data = result.data;
                        // console.log(Data);
                        if (Data.length) {
                            Data[0].index= no;
                            Data[0].Qty = 1;
                            let amt = bill + parseInt(Data[0].Price);
                            setBill(amt);
                            setSaleData([...saleData, Data[0]]);
                            setNo(no+1);
                            setCode("");
                            setLoading(false);
                        } else {
                            alert("No Product Found !");
                            setLoading(false);
                        }
                    } else {
                        showError();
                        console.log("Server Error. Please Try Again Later.(Code : 403)");
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    return (
        <section className="content">
            <Card
                title={() => {
                    return (
                        <>
                            <button onClick={() => navigate.go(-1)}
                                    style={{border: "none", outline: "none", background: "transparent"}}><i
                                className={'fas fa-arrow-left'}></i></button>
                            &nbsp;&nbsp;&nbsp;
                            <span>Customer Sale</span>
                        </>
                    )
                }} className="card">
                <Toast ref={toast}/>
                <div className="row">
                    <div className="col-md-6">
                        <label>Scan/Enter BarCode <code>*</code></label>
                        <InputText value={code} onChange={(e) => setCode(e.target.value)} name='code'
                                   disabled={updateId ? true : false} placeholder={'BarCode........'} autoFocus={true}
                                   onKeyPress={(e) => {
                                       if (e.key == "Enter") {
                                           LoadProductDetail();
                                       }
                                   }}/>
                    </div>
                </div>
                <form id={'Form'} onSubmit={submitHandle}>
                    <div className="row mt-3">
                        <DataTable value={saleData} loading={loading} emptyMessage={'No Record Found !'} paginator
                                   rows={10}
                                   rowsPerPageOptions={[5, 10, 25, 50]}
                                   tableStyle={{minWidth: '50rem', overflow: "auto"}}
                        >
                            <Column field="Code" header="BarCode" style={{minWidth: '10rem'}}/>
                            <Column field="Name" header="Name" style={{minWidth: '10rem'}}/>
                            <Column field="Price" header="Price" style={{minWidth: '10rem'}}/>
                            <Column header="Qty" style={{maxWidth: '7rem'}} body={(p) => {
                                return (
                                    <InputText min={0} placeholder={'Qty'} value={p.Qty} type={'number'} onChange={(e)=>{
                                        let data = [...saleData];
                                        let amt = parseInt(p.Price) * data[p.index].Qty;
                                        amt = bill - amt;
                                        data[p.index].Qty = e.target.value;
                                        amt = amt + (data[p.index].Qty * p.Price);
                                        setBill(amt);
                                        setSaleData(data);
                                        console.log(p);
                                    }} />
                                )
                            }}/>
                            <Column header={'Remove'} style={{minWidth: '5rem'}} body={(p) => {
                                return (
                                    <Button icon={'pi pi-delete-left'} severity={'danger'} onClick={() => {
                                        let data = [...saleData];
                                        if (p.index > -1) { // only splice array when item is found
                                            data.splice(p.index, 1); // 2nd parameter means remove one item only
                                            for (let i=0;i<data.length;i++)
                                            {
                                                data[i].index = i;
                                            }
                                        }
                                        if (data.length <= 0)
                                        {
                                            setNo(0);
                                        }

                                        setSaleData(data);
                                    }}/>
                                )
                            }}/>
                        </DataTable>
                    </div>
                    <div className="divider"/>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <h5>Customer Details</h5>
                        </div>
                        <div className="col-md-6">
                            <label>Customer Name <code>*</code></label>
                            <InputText value={customer} onChange={(e) => setCustomer(e.target.value)} name='customer'
                                       placeholder={'Enter Customer Name'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Total Bill Amount <code>*</code></label>
                                <InputText placeholder={'Enter Bill Amount'} type={'number'} name={'bill'} value={bill}
                                           onChange={(e) => setBill(e.target.value)} className={'form-control'}/>
                            </div>
                        </div>

                    </div>
                    <div className="row mt-3">
                        {
                            updateId ?
                                <Button label={loading ? "Updating..." : "Update Record"} severity={'help'}
                                        disabled={loading}/> :
                                <Button label={loading ? "Saving..." : "Save Record"} disabled={loading}/>
                        }
                        {/*<Button  label={loading ? "Saving..." : "Save Record"} disabled={loading} />*/}
                    </div>
                </form>
            </Card>
        </section>

    );
}
