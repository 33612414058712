import React, {useEffect, useRef, useState} from "react";
import {TabView, TabPanel} from 'primereact/tabview';
import {Toast} from "primereact/toast";
import {useHistory} from "react-router-dom";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import AppLink from "../components/Link";
import {ConfirmPopup, confirmPopup} from 'primereact/confirmpopup';


export default function BranchConfig() {
    const navigate = useHistory();
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [branch, setBranch] = useState('');
    const [location, setLocation] = useState('');
    const [username, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [branchData, setBranchData] = useState([]);
    const [updateId, setUpdateId] = useState('');
    //Toaster Messages
    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    useEffect(() => {
        LoadBranch();
    }, [])

    async function LoadBranch() {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "LoadBranch");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Category.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setBranchData(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    const submitHandle = async (e) => {
        e.preventDefault();
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formEl = document.forms.Form;
        var formdata = new FormData(formEl);
        if (updateId) {
            formdata.append("page", "UpdateBranch");
            formdata.append("id", updateId);
        } else {
            formdata.append("page", "SaveBranch");
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Category.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    LoadBranch();
                    showSuccess(updateId ? "Record Updated Successfully" : "Record Saved Successfully");
                    setBranch("");
                    setLocation("")
                    setUserName("")
                    setEmail("")
                    setPassword("")
                    setUpdateId("")
                } else if (result.status == "2")
                {
                    toast.current.show({severity: 'warn', summary: 'Already Exist !', detail: 'A user with this email already exists', life: 3000});
                }
                else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
                setLoading(false);
            })
            .catch(error => console.log('error', error));
    }

    return (
        <section className="content">
            <Toast ref={toast}/>
            <ConfirmPopup/>
            <div className="card">
                <TabView>
                    <TabPanel header="Branch/Store Config.">
                        <form id={'Form'} onSubmit={submitHandle}>
                            <div className="row">
                                <div className="col-md-6">
                                    <label>Branch Name <code>*</code></label>
                                    <InputText value={branch} onChange={(e) => setBranch(e.target.value)}
                                               name={'branch'}
                                               required={true} placeholder={'Enter Branch Name'}/>
                                </div>
                                <div className="col-md-6">
                                    <label>Location <code>*</code></label>
                                    <InputText value={location} onChange={(e) => setLocation(e.target.value)}
                                               name={'location'}
                                               required={true} placeholder={'Enter Location Name'}/>
                                </div>
                                <div className="col-md-6">
                                    <label>UserName <code>*</code></label>
                                    <InputText value={username} onChange={(e) => setUserName(e.target.value)}
                                               name={'username'}
                                               required={true} placeholder={'Enter UserName'}/>
                                </div>
                                <div className="col-md-6">
                                    <label>Email Address <code>*</code></label>
                                    <InputText value={email} onChange={(e) => setEmail(e.target.value)}
                                               name={'email'}
                                               required={true} placeholder={'Enter Email Address'}/>
                                </div>
                                <div className="col-md-6">
                                    <label>Password <code>*</code></label>
                                    <InputText value={password} onChange={(e) => setPassword(e.target.value)}
                                               name={'password'}
                                               required={true} placeholder={'***********'}/>
                                </div>
                                <div className="col-md-12">
                                    <label>&nbsp;</label>
                                    {
                                        updateId ?
                                            <Button label={loading ? "Updating..." : "Update Record"} disabled={loading}
                                                    className={'form-control'} severity={'help'}
                                            />
                                            :
                                            <Button label={loading ? "Saving..." : "Save Record"} disabled={loading}
                                                    className={'form-control'}/>
                                    }
                                </div>
                            </div>
                        </form>
                        <div className="row mt-4">
                            <DataTable value={branchData} loading={loading} emptyMessage={'No Record Found !'}
                                       paginator
                                       rows={10}
                                       rowsPerPageOptions={[5, 10, 25, 50]}
                                       tableStyle={{minWidth: '50rem', overflow: "auto"}}
                            >
                                <Column field="BranchName" header="Branch Name" style={{minWidth: '10rem'}}/>
                                <Column field="Location" header="Location" style={{minWidth: '10rem'}}/>
                                <Column field="UserName" header="UserName" style={{minWidth: '10rem'}}/>
                                <Column field="Email" header="Email" style={{minWidth: '10rem'}}/>
                                <Column field="Password" header="Password" style={{minWidth: '10rem'}}/>
                                <Column header="Delete" style={{maxWidth: '5rem'}} body={(p) => {
                                    const acceptDelete = async () => {
                                        var myHeaders = new Headers();
                                        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
                                        var formdata = new FormData();
                                        formdata.append("page", "DeleteBranch");
                                        formdata.append("id", p.id);
                                        var requestOptions = {
                                            method: 'POST',
                                            headers: myHeaders,
                                            body: formdata,
                                            redirect: 'follow'
                                        };

                                        await fetch(`${AppLink}Category.php`, requestOptions)
                                            .then(response => response.json())
                                            .then(result => {
                                                console.log(result);
                                                setLoading(false);
                                                if (result.status == "1") {
                                                    // let Data = result.Data;
                                                    LoadBranch();
                                                    showSuccess("Record Deleted Successfully");
                                                } else {
                                                    showError();
                                                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                                                }
                                            })
                                            .catch(error => console.log('error', error));
                                    }
                                    if (p.id !== "1") {
                                        return (
                                            <Button icon={'pi pi-trash'} severity={'danger'} onClick={async (event) => {
                                                confirmPopup({
                                                    target: event.currentTarget,
                                                    message: 'Do you want to delete this record?',
                                                    icon: 'pi pi-info-circle',
                                                    acceptClassName: 'p-button-danger',
                                                    accept() {
                                                        acceptDelete()
                                                    },
                                                    reject() {
                                                        console.log("Reject");
                                                    }
                                                });


                                            }}/>
                                        )
                                    }
                                    else
                                    {
                                        return(
                                            <></>
                                        )
                                    }
                                }}/>
                                <Column header={'Edit'} style={{maxWidth: '5rem'}} body={(p) => {
                                    return (
                                        <Button icon={'pi pi-pencil'} severity={'info'} onClick={() => {
                                            setUpdateId(p.id);
                                            setBranch(p.BranchName)
                                            setLocation(p.Location)
                                            setUserName(p.UserName)
                                            setEmail(p.Email)
                                            setPassword(p.Password)
                                        }}/>
                                    )
                                }}/>
                            </DataTable>
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        </section>
    )
}