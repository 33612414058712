import React, {useEffect, useRef, useState} from "react";
import {TabView, TabPanel} from 'primereact/tabview';
import {Toast} from "primereact/toast";
import {useHistory} from "react-router-dom";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import AppLink from "../components/Link";
import {ConfirmPopup, confirmPopup} from 'primereact/confirmpopup';


export default function Profile() {
    const navigate = useHistory();
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [username, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [updateId, setUpdateId] = useState('');
    //Toaster Messages
    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    useEffect(() => {
        LoadUsersDetail();
    }, [])

    async function LoadUsersDetail() {
        setUserName(await localStorage.getItem("UserName"));
        setEmail(await localStorage.getItem("Email"));
        setPassword(await localStorage.getItem("Password"));
        setUpdateId(await localStorage.getItem("UserId"));
    }

    const submitHandle = async (e) => {
        e.preventDefault();
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formEl = document.forms.Form;
        var formdata = new FormData(formEl);
        if (updateId) {
            formdata.append("page", "UpdateUsersDetail");
            formdata.append("id", updateId);
            formdata.append("pe", localStorage.getItem("Email"));
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}users.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {

                    localStorage.setItem("UserName", username);
                    localStorage.setItem("Email", email);
                    localStorage.setItem("Password", password);
                    showSuccess(updateId ? "Record Updated Successfully" : "Record Saved Successfully");
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000)

                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
                setLoading(false);
            })
            .catch(error => console.log('error', error));
    }

    return (
        <section className="content">
            <Toast ref={toast}/>
            <ConfirmPopup/>
            <div className="card">
                <TabView>
                    <TabPanel header="Profile Configuration">
                        <form id={'Form'} onSubmit={submitHandle}>
                            <div className="row">
                                <div className="col-md-6">
                                    <label>UserName <code>*</code></label>
                                    <InputText value={username} onChange={(e) => setUserName(e.target.value)}
                                               name={'username'}
                                               required={true} placeholder={'Enter UserName'}/>
                                </div>
                                <div className="col-md-6">
                                    <label>Email Address <code>*</code></label>
                                    <InputText value={email} onChange={(e) => setEmail(e.target.value)}
                                               name={'email'}
                                               required={true} placeholder={'Enter Email Address'}/>
                                </div>
                                <div className="col-md-6">
                                    <label>Password <code>*</code></label>
                                    <InputText value={password} onChange={(e) => setPassword(e.target.value)}
                                               name={'password'}
                                               required={true} placeholder={'***********'}/>
                                </div>
                                <div className="col-md-12">
                                    <label>&nbsp;</label>
                                    {
                                        updateId ?
                                            <Button label={loading ? "Updating..." : "Update Record"} disabled={loading}
                                                    className={'form-control'} severity={'help'}
                                            />
                                            :
                                            <Button label={loading ? "Saving..." : "Save Record"} disabled={loading}
                                                    className={'form-control'}/>
                                    }
                                </div>
                            </div>
                        </form>
                    </TabPanel>
                </TabView>
            </div>
        </section>
    )
}