import React, {useState, useEffect, useRef} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Card} from 'primereact/card';
import {useHistory} from "react-router-dom";
import AppLink, {ImageLink} from "../components/Link";
import {Toast} from "primereact/toast";
import {ConfirmPopup, confirmPopup} from 'primereact/confirmpopup';
import {Dialog} from 'primereact/dialog';

export default function CustomerSale() {
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [customerData, setCustomerData] = useState([]);
    const [filterRecord, setFilterRecord] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [updateId, setUpdateId] = useState('');
    const [visible,setVisible] = useState(false);
    const [saleData, setSaleData] = useState([]);
    useEffect(() => {
        LoadData();
    }, [])

    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    const LoadData = async () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "LoadCustomerSale");
        formdata.append("UserId", localStorage.getItem("UserId"));
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Products.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setCustomerData(Data);
                    setFilterRecord(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    const filterData = (e) => {
        let val = e.target.value.toLowerCase();
        if (val) {
            const filter = filterRecord.filter((d) => {
                return d.CustomerName.toLowerCase().indexOf(val) > -1 || d.Bill.toLowerCase().indexOf(val) > -1
            })
            console.log(filter);
            setCustomerData(filter);
        } else {
            setCustomerData(filterRecord);
        }
    }

    function filterDateRecord() {
        const filter = filterRecord.filter((d) => {
            return d.Date >= startDate && d.Date <= endDate
        })
        console.log(filter);
        setCustomerData(filter);
    }

    async function LoadSaleDetail(id) {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "LoadCustomerSaleDetail");
        formdata.append("id", id);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Products.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setSaleData(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    return (
        <section className="content">

            <Card header={() => {
                return (
                    <div className="row justify-content-end gap-4 mt-4">
                        <Button onClick={() => history.push('/add-sale')} label="Sale Items"
                                icon="pi pi-plus"
                                style={{width: "auto"}}/>
                    </div>
                )
            }
            } title={() => {
                return (
                    <>
                        <button onClick={() => history.go(-1)}
                                style={{border: "none", outline: "none", background: "transparent"}}><i
                            className={'fas fa-arrow-left'}></i></button>
                        &nbsp;&nbsp;&nbsp;
                        <span>Sale List</span>
                        <div className="row justify-content-between">
                            <div className="col-md-3 mt-1">
                                <div className="form-group">
                                    <InputText type={'search'} onChange={(e) => filterData(e)}
                                               placeholder={'Keyword Search...'}/>
                                </div>
                            </div>
                            <div className="col-md-7 mt-1">
                                <div className="row">
                                    <div className="col-md-4 mt-1">
                                        <div className="form-group">
                                            <InputText type={'date'} onChange={(e) => setStartDate(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-1">
                                        <div className="form-group">
                                            <InputText type={'date'} onChange={(e) => setEndDate(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mt-1">
                                        <div className="form-group">
                                            <button onClick={filterDateRecord}
                                                    className={'btn btn-primary form-control'}>Search
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }} className="card">
                <Toast ref={toast}/>

                <Dialog header="Sale Detail" visible={visible} style={{width: '50vw'}} onHide={() => setVisible(false)}>
                    <div className="row mt-3">
                        <DataTable value={saleData} loading={loading} emptyMessage={'No Record Found !'} paginator
                                   rows={10}
                                   rowsPerPageOptions={[5, 10, 25, 50]}
                                   tableStyle={{minWidth: '50rem', overflow: "auto"}}
                        >
                            <Column field="Code" header="BarCode" style={{minWidth: '10rem'}}/>
                            <Column field="Price" header="Price" style={{minWidth: '10rem'}}/>
                            <Column field="Qty" header="Qty" style={{minWidth: '10rem'}} />
                        </DataTable>
                    </div>
                </Dialog>

                <ConfirmPopup/>
                <DataTable value={customerData} loading={loading} emptyMessage={'No Record Found !'} paginator
                           rows={10}
                           onRowClick={async (p) => {
                               let id = customerData[p.index].id;
                               setUpdateId(id);
                           }}
                           rowsPerPageOptions={[5, 10, 25, 50]}
                           tableStyle={{minWidth: '50rem', overflow: "auto"}}
                >
                    <Column field="id" header="Sale Id" style={{minWidth: '10rem'}}/>
                    <Column field="CustomerName" header="Customer Name" style={{minWidth: '10rem'}}/>
                    <Column field="Bill" header="Total Bill" style={{minWidth: '10rem'}}/>
                    <Column field="Date" header="Sale Date" style={{minWidth: '10rem'}}/>
                    <Column header={'Show Detail'} style={{minWidth: '5rem'}} body={(p) => {
                        return (
                            <Button icon={'pi pi-file'} severity={'secondary'} onClick={() => {
                                LoadSaleDetail(p.id);
                                setVisible(!visible);
                            }}/>
                        )
                    }}/>
                </DataTable>
            </Card>
        </section>
    );
}
