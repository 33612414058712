import React, {useEffect, useRef, useState} from "react";
import {TabView, TabPanel} from 'primereact/tabview';
import {Toast} from "primereact/toast";
import {useHistory} from "react-router-dom";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import AppLink from "../components/Link";
import {ConfirmPopup, confirmPopup} from 'primereact/confirmpopup';
import {Dropdown} from "primereact/dropdown";


export default function CategoryConfig() {
    const navigate = useHistory();
    const toast = useRef(null);
    //Category
    const [cname, setCname] = useState("");
    const [loading, setLoading] = useState(false);
    const [cUpdate, setCUpdate] = useState("");
    const [categoryData, setCategoryData] = useState([]);
    //SubCategory
    const [cat, setCat] = useState("");
    const [sCname, setsCname] = useState("");
    const [sCUpdate, setSCUpdate] = useState("");
    const [subCategoryData, setSubCategoryData] = useState([]);
    //Toaster Messages
    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    useEffect(() => {
        LoadCategory();
        LoadSubCategory();
    }, [])

    async function LoadCategory() {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "LoadCategory");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Category.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setCategoryData(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }
    //SubCategory
    async function LoadSubCategory() {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "LoadSubCategory");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Category.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setSubCategoryData(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    const categorySubmitHandle = async (e) => {
        e.preventDefault();
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        // var formEl = document.forms.Form;
        var formdata = new FormData();
        formdata.append("Name", cname);
        if (cUpdate) {
            formdata.append("page", "UpdateCategory");
            formdata.append("id", cUpdate);
        } else {
            formdata.append("page", "SaveCategory");
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Category.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    LoadCategory();
                    showSuccess(cUpdate ? "Record Updated Successfully" : "Record Saved Successfully");
                    setCUpdate("");
                    setCname("");
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
                setLoading(false);
            })
            .catch(error => console.log('error', error));
    }

    //Subcategory
    const subcategorySubmitHandle = async (e) => {
        e.preventDefault();
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        // var formEl = document.forms.Form;
        var formdata = new FormData();
        formdata.append("Category", cat);
        formdata.append("Name", sCname);
        if (sCUpdate) {
            formdata.append("page", "UpdateSubCategory");
            formdata.append("id", sCUpdate);
        } else {
            formdata.append("page", "SaveSubCategory");
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Category.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    LoadSubCategory();
                    showSuccess(sCUpdate ? "Record Updated Successfully" : "Record Saved Successfully");
                    setSCUpdate("");
                    setsCname("");
                    setCat("");
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
                setLoading(false);
            })
            .catch(error => console.log('error', error));
    }

    const dt = useRef(null);

    const cols = [
        { field: 'id', header: 'ID' },
        { field: 'Name', header: 'Category Name' }
    ];
    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));
    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, categoryData);
                doc.save('category.pdf');
            });
        });
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(categoryData);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'category');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });
                module.default.saveAs(data, fileName + EXCEL_EXTENSION);
            }
        });
    };


    return (
        <section className="content">
            <Toast ref={toast}/>
            <ConfirmPopup/>
            <div className="card">
                <TabView>
                    <TabPanel header="Category Config.">
                        <div className="row">
                            <div className="col-md-7">
                                <label>Category Name <code>*</code></label>
                                <InputText value={cname} onChange={(e) => setCname(e.target.value)} name={'Category'}
                                           required={true} placeholder={'Enter Category Name'}/>
                            </div>
                            <div className="col-md-5">
                                <label>&nbsp;</label>
                                {
                                    cUpdate ?
                                        <Button label={loading ? "Updating..." : "Update Record"} disabled={loading}
                                                className={'form-control'} severity={'help'}
                                                onClick={categorySubmitHandle}/>
                                        :
                                        <Button label={loading ? "Saving..." : "Save Record"} disabled={loading}
                                                className={'form-control'} onClick={categorySubmitHandle}/>
                                }
                            </div>
                        </div>
                        <div className="row align-items-center" style={{ margin : 15 }}>
                            <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
                            <Button className={'ml-2'} type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
                            <Button className={'ml-2'} type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf} data-pr-tooltip="PDF" />
                        </div>
                        <div className="row mt-4">
                            <DataTable ref={dt} value={categoryData} loading={loading} emptyMessage={'No Record Found !'}
                                       paginator
                                       rows={10}
                                // onRowClick={async (p) => {
                                //     let id = categoryData[p.index].id;
                                //     setCUpdate(id);
                                //     setCname(categoryData[p.index].Name);
                                // }}
                                       rowsPerPageOptions={[5, 10, 25, 50]}
                                       tableStyle={{minWidth: '50rem', overflow: "auto"}}
                            >
                                <Column field="Name" header="Category Name" style={{minWidth: '10rem'}}/>
                                <Column header="Delete" style={{maxWidth: '5rem'}} body={(p) => {
                                    const acceptDelete = async () => {
                                        var myHeaders = new Headers();
                                        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
                                        var formdata = new FormData();
                                        formdata.append("page", "DeleteCategory");
                                        formdata.append("id", p.id);
                                        var requestOptions = {
                                            method: 'POST',
                                            headers: myHeaders,
                                            body: formdata,
                                            redirect: 'follow'
                                        };

                                        await fetch(`${AppLink}Category.php`, requestOptions)
                                            .then(response => response.json())
                                            .then(result => {
                                                console.log(result);
                                                setLoading(false);
                                                if (result.status == "1") {
                                                    // let Data = result.Data;
                                                    LoadCategory();
                                                    showSuccess("Record Deleted Successfully");
                                                } else {
                                                    showError();
                                                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                                                }
                                            })
                                            .catch(error => console.log('error', error));
                                    }
                                    return (
                                        <Button icon={'pi pi-trash'} severity={'danger'} onClick={async (event) => {
                                            confirmPopup({
                                                target: event.currentTarget,
                                                message: 'Do you want to delete this record?',
                                                icon: 'pi pi-info-circle',
                                                acceptClassName: 'p-button-danger',
                                                accept() {
                                                    acceptDelete()
                                                },
                                                reject() {
                                                    console.log("Reject");
                                                }
                                            });


                                        }}/>
                                    )
                                }}/>
                                <Column header={'Edit'} style={{maxWidth: '5rem'}} body={(p) => {
                                    return (
                                        <Button icon={'pi pi-pencil'} severity={'info'} onClick={() => {
                                            setCUpdate(p.id);
                                            setCname(p.Name);
                                        }}/>
                                    )
                                }}/>
                            </DataTable>
                        </div>
                    </TabPanel>
                    <TabPanel header="SubCategory Config.">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Category <code>*</code></label>
                                    <Dropdown name={'cat'} value={cat} onChange={(e) => setCat(e.target.value)}
                                              options={categoryData} optionLabel="Name" optionValue={'id'}
                                              placeholder="Select Category" className='form-control'/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>SubCategory Name <code>*</code></label>
                                    <InputText value={sCname} onChange={(e) => setsCname(e.target.value)}
                                               required={true} placeholder={'Enter SubCategory Name'} className={'form-control'}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <label>&nbsp;</label>
                                {
                                    sCUpdate ?
                                        <Button label={loading ? "Updating..." : "Update Record"} disabled={loading}
                                                className={'form-control'} severity={'help'}
                                                onClick={subcategorySubmitHandle}/>
                                        :
                                        <Button label={loading ? "Saving..." : "Save Record"} disabled={loading}
                                                className={'form-control'} onClick={subcategorySubmitHandle}/>
                                }
                            </div>
                        </div>
                        <div className="row mt-4">
                            <DataTable value={subCategoryData} loading={loading} emptyMessage={'No Record Found !'}
                                       paginator
                                       rows={10}
                                       rowsPerPageOptions={[5, 10, 25, 50]}
                                       tableStyle={{minWidth: '50rem', overflow: "auto"}}
                            >
                                <Column field="CName" header="Category Name" style={{minWidth: '10rem'}}/>
                                <Column field="Name" header="Name" style={{minWidth: '10rem'}}/>
                                <Column header="Delete" style={{maxWidth: '5rem'}} body={(p) => {
                                    const acceptDelete = async () => {
                                        var myHeaders = new Headers();
                                        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
                                        var formdata = new FormData();
                                        formdata.append("page", "DeleteSubCategory");
                                        formdata.append("id", p.id);
                                        var requestOptions = {
                                            method: 'POST',
                                            headers: myHeaders,
                                            body: formdata,
                                            redirect: 'follow'
                                        };

                                        await fetch(`${AppLink}Category.php`, requestOptions)
                                            .then(response => response.json())
                                            .then(result => {
                                                console.log(result);
                                                setLoading(false);
                                                if (result.status == "1") {
                                                    // let Data = result.Data;
                                                    LoadSubCategory();
                                                    showSuccess("Record Deleted Successfully");
                                                } else {
                                                    showError();
                                                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                                                }
                                            })
                                            .catch(error => console.log('error', error));
                                    }
                                    return (
                                        <Button icon={'pi pi-trash'} severity={'danger'} onClick={async (event) => {
                                            confirmPopup({
                                                target: event.currentTarget,
                                                message: 'Do you want to delete this record?',
                                                icon: 'pi pi-info-circle',
                                                acceptClassName: 'p-button-danger',
                                                accept() {
                                                    acceptDelete()
                                                },
                                                reject() {
                                                    console.log("Reject");
                                                }
                                            });


                                        }}/>
                                    )
                                }}/>
                                <Column header={'Edit'} style={{maxWidth: '5rem'}} body={(p) => {
                                    return (
                                        <Button icon={'pi pi-pencil'} severity={'info'} onClick={() => {
                                            setSCUpdate(p.id);
                                            setCat(p.Category);
                                            setsCname(p.Name);
                                        }}/>
                                    )
                                }}/>
                            </DataTable>
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        </section>
    )
}
