import React, {useState, useEffect, useRef} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Card} from 'primereact/card';
import {useHistory} from "react-router-dom";
import AppLink, {ImageLink} from "../components/Link";
import {Toast} from "primereact/toast";
import {ConfirmPopup, confirmPopup} from 'primereact/confirmpopup';

export default function Orders() {
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [customerData, setCustomerData] = useState([]);
    const [filterRecord, setFilterRecord] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [updateId, setUpdateId] = useState('');
    const dt = useRef(null);
    useEffect(() => {
        LoadData();
    }, [])

    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    const LoadData = async () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "LoadOrders");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Products.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setCustomerData(Data);
                    setFilterRecord(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    return (
        <section className="content">
            <Card title={() => {
                return (
                    <>
                        <button onClick={() => history.go(-1)}
                                style={{border: "none", outline: "none", background: "transparent"}}><i
                            className={'fas fa-arrow-left'}></i></button>
                        &nbsp;&nbsp;&nbsp;
                        <span>Orders List</span>
                    </>
                )
            }} className="card">
                <Toast ref={toast}/>
                <ConfirmPopup/>
                <DataTable ref={dt} value={customerData} loading={loading} emptyMessage={'No Record Found !'} paginator
                           rows={10}
                           onRowClick={async (p) => {
                               let id = customerData[p.index].id;
                               setUpdateId(id);
                           }}
                           rowsPerPageOptions={[5, 10, 25, 50]}
                           tableStyle={{minWidth: '50rem', overflow: "auto"}}
                >
                    <Column header="Customer Name" body={(p) => {
                        let data = JSON.parse(p.customer);
                        return (
                            <span>{data[0].name} {data[0].last_name} </span>
                        )
                    }} style={{minWidth: '10rem'}}/>
                    <Column header="Customer Email" body={(p) => {
                        let data = JSON.parse(p.customer);
                        return (
                            <span>{data[0].email}</span>
                        )
                    }} style={{minWidth: '10rem'}}/>
                    <Column header="Customer Contact" body={(p) => {
                        let data = JSON.parse(p.customer);
                        return (
                            <span>{data[0].phone}</span>
                        )
                    }} style={{minWidth: '10rem'}}/>
                    <Column header="Address" body={(p) => {
                        let data = JSON.parse(p.customer);
                        return (
                            <>
                                <p>Address: {data[0].address}</p>
                                <p>City: {data[0].city}</p>
                                <p>State: {data[0].state}</p>
                                <p>Zip: {data[0].zip}</p>
                            </>
                        )
                    }} style={{minWidth: '10rem'}}/>
                    <Column header="Products" body={(p) => {
                        return(
                            <DataTable value={JSON.parse(p.items)} loading={loading} emptyMessage={'No Items Found !'}>
                                <Column header="Name" body={(p) => {
                                    return (
                                        <span>{p.name}</span>
                                    )
                                }} style={{minWidth: '5rem'}}/>
                                <Column header="Price" body={(p) => {
                                    return (
                                        <span>{p.price}</span>
                                    )
                                }} style={{minWidth: '5rem'}}/>
                            </DataTable>
                        )
                    }} style={{minWidth: '10rem'}}/>
                    <Column field="paid_amount" header="Total Amount" style={{minWidth: '10rem'}}/>
                    <Column field="Date" header="Detail" style={{minWidth: '10rem'}}/>
                    <Column header={'Complete Order'} style={{minWidth: '5rem'}} body={(p) => {
                        const acceptDelete = async () => {
                            var myHeaders = new Headers();
                            myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
                            var formdata = new FormData();
                            formdata.append("page", "AcceptOrder");
                            formdata.append("id", p.id);
                            var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: formdata,
                                redirect: 'follow'
                            };

                            await fetch(`${AppLink}Products.php`, requestOptions)
                                .then(response => response.json())
                                .then(result => {
                                    console.log(result);
                                    setLoading(false);
                                    if (result.status == "1") {
                                        // let Data = result.Data;
                                        LoadData();
                                        showSuccess("Order Completed Successfully");

                                    } else {
                                        showError();
                                        console.log("Server Error. Please Try Again Later.(Code : 403)");
                                    }
                                })
                                .catch(error => console.log('error', error));
                        }
                        return (
                            <Button icon={'pi pi-check'} severity={'success'} onClick={async (event) => {
                                confirmPopup({
                                    target: event.currentTarget,
                                    message: 'Do you want to complete this order?',
                                    icon: 'pi pi-info-circle',
                                    acceptClassName: 'p-button-success',
                                    accept() {
                                        acceptDelete()
                                    },
                                    reject() {
                                        console.log("Reject");
                                    }
                                });
                            }}/>
                        )
                    }}/>
                </DataTable>
            </Card>
        </section>
    );
}
